











































import type { ComponentInstance } from '@nuxtjs/composition-api';
import { Component, Vue, Prop, Inject } from 'nuxt-property-decorator';

import { IconPlus } from '@/icons';

/**
 * Tip: Use <DisclosureScope> as wrapper to open only one <Disclosure> at a time.
 */
@Component({
  components: {
    IconPlus,
  },
})
export default class Disclosure extends Vue {
  @Prop(String)
  title!: string;

  @Prop({ type: String, required: true })
  id!: string;

  @Prop(Boolean)
  openFromStart!: boolean

  @Prop(Boolean)
  sm!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  hideButton!: boolean

  @Inject({ default: null })
  registerDisclosure: ((disclosure: ComponentInstance) => void) | null;

  active = false;

  created () {
    this.registerDisclosure?.(this);
    this.active = this.openFromStart;

    this.$on('expanded', () => { this.active = true });
    this.$on('collapsed', () => { this.active = false });
  }

  toggleActive () {
    this.active = !this.active;
    this.$emit(this.active ? 'expanded' : 'collapsed');

    this.$emit('active', this.id);
  }
}
