






import type { ComponentInstance } from '@nuxtjs/composition-api';
import { Component, Prop, Provide, Vue } from 'nuxt-property-decorator';

/**
 * Use <DisclosureScope> to open only one <Disclosure> at a time.
 */
@Component
export default class DisclosureScope extends Vue {
  disclosures: ComponentInstance[] = [];

  @Prop({ default: 'div' })
  tag: string

  @Provide()
  registerDisclosure (disclosure: ComponentInstance) {
    this.disclosures.push(disclosure);
    disclosure.$on('expanded', () => this.onDisclosureExpanded(disclosure));
  }

  onDisclosureExpanded (disclosure: ComponentInstance) {
    this.disclosures
      .filter(d => d !== disclosure)
      .forEach(d => d.$emit('collapsed'));
  }
}
